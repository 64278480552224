import Datepicker from "vanillajs-datepicker/Datepicker";
import "vanillajs-datepicker/css/datepicker.css";
import "@common/styles/components/datepicker.css";

const setupField = (input) => {
  const opts = {
    autohide: true,
    minDate: new Date(1900, 0, 1),
    maxDate: new Date(),
    orientation: "bottom",
    prevArrow: `
      <svg viewBox="0 0 24 24">
        <path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
      </svg>
    `,
    nextArrow: `
      <svg viewBox="0 0 24 24">
        <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
      </svg>
    `,
  };

  if (input.dataset.datepickerDefaultYear) {
    opts.startView = 2;
  }

  new Datepicker(input, opts);
};

export default {
  init: () => {
    document.querySelectorAll("[data-datepicker]:not(.datepicker-input)").forEach((input) => {
      setupField(input);
    });
  },
};
