import TomSelect from "tom-select/dist/esm/tom-select";
import "@common/styles/components/dropdown.css";

const setupSingleField = (selectEl) => {
  new TomSelect(selectEl, {
    selectOnTab: true,
    maxOptions: null,
    onChange: function () {
      this.control_input.blur();
    },
    closeAfterSelect: true,
    onDropdownClose: function () {
      this.control_input.blur();
    },
  });
};

const setupMultipleField = (selectEl) => {
  new TomSelect(selectEl, {
    selectOnTab: true,
    closeAfterSelect: true,
  });
};

export default {
  init: () => {
    document.querySelectorAll("select.js-dropdown:not(.tomselected)").forEach((el) => {
      setupSingleField(el);
    });

    document.querySelectorAll("select.js-multidropdown:not(.tomselected)").forEach((el) => {
      setupMultipleField(el);
    });
  },

  reset: (form, fireChange = true) => {
    form.querySelectorAll("select.tomselected").forEach((el) => {
      el.tomselect.input.innerHTML = el.tomselect.revertSettings.innerHTML;
      el.tomselect.sync();

      if (fireChange) {
        el.dispatchEvent(new Event("change"));
      }
    });
  },
};
