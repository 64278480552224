const findFieldWrapper = (el) => {
  if (el) {
    const selectors = [".form-field-wrapper", ".form-field", ".middleColumn", ".field"];

    for (const selector of selectors) {
      const wrapper = el.closest(selector);

      if (wrapper) {
        return wrapper;
      }
    }
  }
};

const shouldIgnoreField = (field) => {
  return (
    (findFieldWrapper(field) && findFieldWrapper(field).offsetHeight === 0) ||
    field.getAttribute("readonly") ||
    !field.name
  );
};

export const showFieldError = (form, fieldName, message) => {
  const field = form.querySelector(`[name="${fieldName}"]`);
  field.classList.add("has-error");

  const fieldWrapper = findFieldWrapper(field);

  if (fieldWrapper) {
    const existingErrorMessage = fieldWrapper.querySelector(".form-field-message");

    if (existingErrorMessage) {
      existingErrorMessage.remove();
    }

    const errorMessage = `
        <p class="form-field-message bad">
          ${message}
        </p>
      `;

    fieldWrapper.insertAdjacentHTML("beforeend", errorMessage);
    fieldWrapper.classList.add("has-error");

    ["blur", "change", "input"].forEach((type) => {
      fieldWrapper.addEventListener(
        type,
        () => {
          fieldWrapper.classList.remove("has-error");
          fieldWrapper.classList.remove("holder-error");
          field.classList.remove("has-error");

          const errorMessage = fieldWrapper.querySelector(".form-field-message");

          if (errorMessage) {
            errorMessage.remove();
          }
        },
        { capture: true, once: true },
      );
    });
  }
};

export const showFormErrors = (form, messages) => {
  const messageHolder = form.querySelector(".form-messages");

  if (messageHolder) {
    messageHolder.innerHTML = null;

    messages.forEach((message) => {
      const errorMessage = `
          <p class="form-field-message bad">
            ${message}
          </p>
        `;

      messageHolder.insertAdjacentHTML("beforeend", errorMessage);
    });
  }
};

const getErrorMessage = (field) => {
  if (field.type === "file") {
    if (field.required && !field.files.length) {
      return "Required";
    }
  }

  if (field.validity.valueMissing) {
    return "Required";
  }

  if (field.dataset.requiredIf && !field.value.trim()) {
    return "Required";
  }

  if (field.type === "email" && field.validity.typeMismatch) {
    return "Please enter a valid email address";
  }

  return "Required";
};

const validateField = (field) => {
  if (field.type === "file") {
    if (field.required && !field.files.length) {
      return false;
    } else {
      return true;
    }
  }

  if (field.dataset.requiredIf && !field.value.trim()) {
    return false;
  }

  if (field.type === "radio") {
    const fieldWrapper = findFieldWrapper(field);

    if (fieldWrapper && fieldWrapper.querySelector(".optionset[aria-required]")) {
      return fieldWrapper.querySelectorAll('input[type="radio"]:checked').length > 0;
    }
  }

  return field.checkValidity();
};

const validate = (form) => {
  const invalidFields = [];

  form.querySelectorAll("input:not([type=hidden]), textarea, select").forEach((field) => {
    if (shouldIgnoreField(field)) {
      return;
    }

    if (!validateField(field)) {
      const message = getErrorMessage(field);

      invalidFields.push({
        field,
        message,
      });
    }
  });

  return invalidFields;
};

export const validateForm = (form) => {
  const result = validate(form);

  if (result.length === 0) {
    return true;
  }

  result.forEach((invalid) => {
    const fieldName = invalid.field.name;

    if (fieldName) {
      const message = invalid.message;

      showFieldError(form, fieldName, message);
    }
  });

  return false;
};
