import IMask from "imask/esm/imask";
import "imask/esm/masked/date";
import "imask/esm/masked/number";
import "imask/esm/masked/pattern";

export default {
  init: (form) => {
    const fields = form.querySelectorAll('input[type="tel"], input.as-date, input.currency, input.as-license');

    if (!fields.length) {
      return;
    }

    fields.forEach((field) => {
      if (field.type === "tel") {
        IMask(field, {
          mask: "000-000-0000",
        });
      }

      if (field.classList.contains("as-license")) {
        const licenseState = form.querySelector('select[name="LicenseState"]');

        if (licenseState) {
          let mask;

          if (licenseState.value === "NY") {
            mask = IMask(field, {
              mask: "000000000",
            });
          }

          licenseState.addEventListener("change", (e) => {
            if (e.target.value === "NY") {
              mask = IMask(field, {
                mask: "000000000",
              });
            } else {
              mask && mask.destroy();
            }
          });
        }
      }

      if (field.classList.contains("currency")) {
        IMask(field, {
          mask: "$num",
          blocks: {
            num: {
              mask: Number,
              scale: 2,
              signed: false,
              thousandsSeparator: ",",
              padFractionalZeros: true,
              normalizeZeros: true,
              radix: ".",
              mapToRadix: ["."],
              min: 0,
              max: 10000,
              prepare: (str) => {
                const num = Number(str);
                return num.toFixed(2);
              },
              overwrite: true,
            },
          },
        });
      }

      if (field.classList.contains("as-date")) {
        const pattern = "MM/DD/YYYY";

        const mask = IMask(field, {
          mask: Date,
          pattern: pattern,
          min: new Date(1920, 0, 1),
          max: new Date(),
          autofix: true,
          overwrite: true,
          blocks: {
            MM: {
              mask: IMask.MaskedRange,
              from: 1,
              to: 12,
              placeholderChar: "M",
            },
            DD: {
              mask: IMask.MaskedRange,
              from: 1,
              to: 31,
              placeholderChar: "D",
            },
            YYYY: {
              mask: IMask.MaskedRange,
              from: 1900,
              to: 9999,
              placeholderChar: "Y",
            },
          },
          format: (date) => {
            let day = date.getDate();
            let month = date.getMonth() + 1;
            const year = date.getFullYear();

            if (day < 10) day = "0" + day;
            if (month < 10) month = "0" + month;

            return [month, day, year].join("/");
          },
          parse: (str) => {
            const yearMonthDay = str.split("/");
            return new Date(yearMonthDay[2], yearMonthDay[0] - 1, yearMonthDay[1]);
          },
        });

        mask.on("accept", () => {
          if (mask.value === pattern) {
            field.classList.remove("is-filled");
            field.classList.remove("has-value");
          } else {
            field.classList.add("has-value");
          }
        });
        mask.on("complete", () => field.classList.add("is-filled"));

        field.addEventListener("changeDate", () => {
          mask.updateValue();
        });
      }
    });
  },
};
