const handleToggle = (field) => {
  const form = field.closest("form");
  const name = field.name;
  const selectedValue = field.value;

  form.querySelectorAll(`[data-toggle-if^="${name}|"]`).forEach((input) => {
    const fieldWrapper = input.closest(".form-field-wrapper");

    let shouldShow = false;

    if (!fieldWrapper) {
      return;
    }

    if (field.type === "radio") {
      const targetValues = input.dataset.toggleIf.split("|");
      targetValues.shift();
      shouldShow = targetValues.indexOf(selectedValue) !== -1;
    }

    if (field.type === "checkbox") {
      const targetValues = input.dataset.toggleIf.split("|");
      targetValues.shift();

      const targetChecked = targetValues[0] === "1";
      shouldShow = field.checked === targetChecked;
    }

    if (shouldShow) {
      fieldWrapper.style.display = null;
    } else {
      fieldWrapper.style.display = "none";
      fieldWrapper.querySelectorAll("input:not([type=checkbox]):not([type=radio])").forEach((x) => (x.value = null));
      fieldWrapper.classList.remove("has-error");
      fieldWrapper.querySelectorAll(".has-error").forEach((x) => x.classList.remove("has-error"));
      fieldWrapper.querySelectorAll(".form-field-message").forEach((x) => x.remove());
    }
  });
};

export default {
  init: (form) => {
    form.querySelectorAll("input[type=radio], input[type=checkbox]").forEach((field) => {
      if (field.checked) {
        handleToggle(field);
      }

      field.addEventListener("change", (e) => handleToggle(e.target));
    });
  },
};
